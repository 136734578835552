<!-- 话题管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建话题</a-button>
  <a-table
    bordered
    rowKey="logicId"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    :loading="pagination.loading"
  >
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="modifyModal.title"
    v-model:visible="modifyModal.visible"
    :confirm-loading="modifyModal.loading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-spin v-if="modifyModal.status" />
    <a-form
      v-else
      ref="formRef"
      :model="modifyModal.data"
      :rules="modifyModal.rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="话题名称" name="name">
        <a-input v-model:value="modifyModal.data.name" />
      </a-form-item>
      <a-form-item label="关联菜单" name="menuLogicId">
        <a-select v-model:value="modifyModal.data.menuLogicId">
          <a-select-option
            v-for="menu of menuArr"
            :key="menu.logicId"
            :value="menu.logicId"
          >{{ menu.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="单品三级类目" name="goodsTypeThreeId">
        <a-cascader
          placeholder="请选择"
          change-on-select
          :options="categoryArr"
          :fieldNames="{
            label: 'name',
            value: 'logicId',
            children: 'childList'
          }"
          @change="categoryChange"
        />
      </a-form-item> -->
      <!-- <a-form-item label="话题头像" name="headPortrait">
        <a-upload
          v-model:fileList="fileList"
          name="file"
          accept="image/*"
          list-type="picture"
          :action="qiniuUrl"
          :data="fileData"
          :beforeUpload="handlerBeforeUpload"
          @change="handlerFileChange"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            上传
          </a-button>
        </a-upload>
      </a-form-item>-->
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRaw } from "vue";
import { saveOrUpdate, page, detail } from "@/api/topic";
import { getAllCategoryTwo } from "@/api/category";
import { page as menuPage } from "@/api/menu";
import urls from "@/common/urls.js";
import { uploadFile } from "@/api/planet";
import { message } from "ant-design-vue";
export default {
  setup() {
    const {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      handlerBeforeUpload,
      handlerFileChange,
      qiniuUrl,
      fileData,
      fileList,
      getMenuList,
      menuArr,
      categoryArr,
      categoryChange
    } = topicManage();
    return {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      handlerBeforeUpload,
      handlerFileChange,
      qiniuUrl,
      fileData,
      fileList,
      getMenuList,
      menuArr,
      categoryArr,
      categoryChange
    };
  }
};
function topicManage() {
  // 表格标题配置
  const columns = [
    { title: "ID", dataIndex: "logicId", width: "15%" },
    { title: "标题", dataIndex: "name", width: "65%" },
    {
      title: "操作",
      dataIndex: "operation",
      width: "20%",
      slots: { customRender: "operation" }
    }
  ];
  const rulesMod = {
    // 修改规则
    name: { required: true, message: "请输入话题名称", trigger: "blur" },
    menuLogicId: { required: true, message: "必填项", trigger: "blur" },
    // goodsTypeThreeId: {
    //   type: "array",
    //   required: true,
    //   message: "必填项",
    //   trigger: "blur"
    // }
    // headPortrait: { required: true, message: "请选择话题头像", trigger: "blur" }
  };
  const source = ref([]); // 显示的表格数据
  const formRef = ref();
  console.log("qiniuBase", urls);
  // ， ，
  /**
   * title: modal标题
   * visible: modal是否出现
   * loading：modal点击提交按钮是否加载
   * status：modal出现之后是否加载
   * form: 表单数据对象
   * data：网络请求的数据
   * rules: 表单提交轨辙
   * selects: 选择数据
   */
  const modifyModal = ref({
    title: "",
    visible: false,
    loading: false,
    status: true,
    form: {},
    data: {},
    rules: rulesMod,
    selects: []
  });
  const fileList = ref([]);
  const qiniuUrl = ref(urls.qiniuBase);
  const fileData = ref({});
  const handlerBeforeUpload = async file => {
    const { data } = await uploadFile(file.name);
    console.log("data", data);
    fileData.value = data;
  };
  const handlerFileChange = ({ file }) => {
    console.log("file", file);
    const { status, response } = file;
    if (status === "done") {
      modifyModal.value.data.headPortrait = `${urls.qiniu}${response.key}`;
      if (response.key) {
        fileList.value = [
          {
            name: response.key,
            response: { key: response.key },
            status: "done",
            url: `${urls.qiniu}${response.key}`,
            uid: "-1"
          }
        ];
      }
    }
  };

  const pagination = ref({
    // 分页配置
    loading: true,
    position: "bottom",
    total: 0, // 条数
    defaultPageSize: 20, // 默认分页条数
    pageSizeOptions: ["5", "10", "15", "20", "30"],
    showSizeChanger: true, // 是否可以改变 pageSize
    showQuickJumper: true, // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: false, // 只有一页时是否隐藏分页器
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows);
      pagination.value.loading = true;
      getList(selectedRowKeys, selectedRows);
    }
  });
  // 加载修改页面
  // const loadingMod = async (logicId) => {
  //   const { data, status } = await detail({ id: logicId });

  //   if (status == "200") {
  //     const { name, starId, logicId } = data;
  //     modifyModal.value.data = { name, starId, logicId }; // 将要修改的数据加载到modal
  //     modifyModal.value.status = false; //停止modal加载状态
  //     return;
  //   }
  //   message.warning("系统繁忙");
  // };
  const loadingLocal = row => {
    const { name, menuLogicId, logicId } = row;
    modifyModal.value.data = { name, logicId, menuLogicId };
    // if (headPortrait) {
    //   const key = headPortrait.split("/").pop();
    //   fileList.value = [
    //     {
    //       name: key || "",
    //       response: { key: key || "" },
    //       status: "done",
    //       url: headPortrait,
    //       uid: "-1"
    //     }
    //   ];
    // }
    modifyModal.value.status = false;
  };
  // 触发修改页面
  const editCates = row => {
    modifyModal.value.title = "修改话题";
    modifyModal.value.visible = true; // 打开modal
    modifyModal.value.status = true;
    loadingLocal(row);
  };
  // 触发新建页面
  const newCreate = () => {
    modifyModal.value.title = "新建话题";
    modifyModal.value.visible = true; // 打开modal
    modifyModal.value.data = {}; // 初始化modal重的表格数据
    modifyModal.value.status = false; // 停止modal加载状态
    modifyModal.value.loading = false; // 提交按钮加载状态
  };

  const handlerCancel = async () => {
    modifyModal.value.status = true;
    modifyModal.value.loading = false; // 提交按钮加载状态
  };
  const handleOk = () => {
    formRef.value
      .validate()
      .then(() => {
        const exce = async () => {
          modifyModal.value.loading = true;
          const {
            name,
            menuLogicId,
            logicId,
          } = modifyModal.value.data;
          // if (goodsTypeThreeId.length < 3) {
          //   message.warning("必须选到三级类目！");
          //   modifyModal.value.loading = false;
          //   return;
          // }
          const { status, msg } = await saveOrUpdate({
            name,
            menuLogicId,
            logicId,
          });
          if (status == "200") {
            message.success(msg);
            modifyModal.value.loading = false;
            modifyModal.value.visible = false;
            getList();
            return;
          }
          modifyModal.value.loading = false;
          message.error(msg);
        };
        exce();
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  // 获取分页数据
  async function getList(pageNo = "1", pageSize = "20") {
    const { data, status } = await page({ pageNo, pageSize });
    if (status == "200") {
      const { total, dataOnThisPage } = data;
      pagination.value.total = total || 0;
      source.value = dataOnThisPage || [];
    }
    pagination.value.loading = false;
  }
  // 获取菜单数据
  const menuArr = ref([]);
  const getMenuList = async () => {
    const { data } = await menuPage({ pageNo: "1", pageSize: "1000" });
    menuArr.value = data.dataOnThisPage;
  };

  // 获取类目数据
  const categoryArr = ref([]);
  const getCategoryList = async () => {
    const { data } = await getAllCategoryTwo();
    categoryArr.value = data;
  };
  const categoryChange = val => {
    modifyModal.value.data.goodsTypeThreeId = val;
  };

  onMounted(getMenuList);
  onMounted(getList);
  // onMounted(getCategoryList);
  onMounted(async () => {
    // const { data } = await getStarList({ pageNum: 1, pageSize: 100 });
    // const { dataOnThisPage } = data;
    // modifyModal.value.selects = dataOnThisPage || [];
  });
  return {
    formRef,
    columns,
    pagination,
    source,
    modifyModal,
    editCates,
    handlerCancel,
    handleOk,
    newCreate,
    handlerBeforeUpload,
    handlerFileChange,
    qiniuUrl,
    fileData,
    fileList,
    getMenuList,
    menuArr,
    categoryArr,
    categoryChange
  };
}
</script>

<style>
</style>
