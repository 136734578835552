import { request } from '../utils/axios'
import { stringify } from 'qs'

/*
 * @Description: 话题API
 * @Author: javon.gao
 * @Date: 2021-04-09 23:44:59
 * @LastEditors: javon.gao
 * @LastEditTime: 2021-04-11 22:00:52
 */

// 新增或修改话题
export function saveOrUpdate(params) {
  return request({
    url: `/wuan/admin/star/topic/saveOrUpdate?${stringify(params)}`,
    method: 'post',
    data: params
  })
}
export function page(params) {
  return request({
    url: `/wuan/admin/star/topic/page?${stringify(params)}`,
    method: 'get'
  })
}
export function detail(params) {
  return request({
    url: `/wuan/admin/star/menu/detail?${stringify(params)}`,
    method: 'get'
  })
}
