import { request } from "../utils/axios";
import { stringify } from "qs";

/*
 * @Description: 菜单API
 * @Author: javon.gao
 * @Date: 2021-04-09 23:31:57
 * @LastEditors: javon.gao
 * @LastEditTime: 2021-04-11 22:27:15
 */

// 新增或修改二、三级分类
export function saveOrUpdate(params) {
  return request({
    url: `/wuan/admin/star/menu/saveOrUpdate?${stringify(params)}`,
    method: "post",
    data: params
  });
}
export function page(params) {
  return request({
    url: `/wuan/admin/star/menu/page?${stringify(params)}`,
    method: "get"
  });
}

export function detail(params) {
  return request({
    url: `/wuan/admin/star/menu/detail?${stringify(params)}`,
    method: "get"
  });
}
export function pageItem(params) {
  return request({
    url: `/wuan/admin/star/type/page?${stringify(params)}`,
    method: "get"
  });
}
